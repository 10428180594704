.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3em;
}

button {
  background: transparent;
  border-radius: 3px;
  border: 2px solid palevioletred;
  color: palevioletred;
  margin: 1em 1em;
  padding: 0.25em 1em;
}

footer {
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 5px -5px #333;
  padding: 20px;
  font-size: small;

  @media (max-width: 768px) {
    position: absolute;
  }
}

/* auto generated by create-react-app */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}